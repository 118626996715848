<template>
    <div class="mainbox">
        <div class="school_detail">
            <div class="line"></div>
            <div class="s_til">校园风采</div>
            <div @click="goNext(`/gxGathers/school/school?id=${id}`)" class="s_tils">
                <img src="~@/assets/img/TechnicalCollege/11.png" alt="">
            </div>
        </div>

        <div class="detail_list">
            <div v-for="(item, index) in lists" :key="index" class="detail_item">
                <div class="detail_item_img">
                    <img :src="item.teacherImg" alt="">
                </div>
                <div class="detail_item_til">
                    <div class="elp"> {{ item.introduce }}</div>

                </div>

            </div>
            <el-empty style="margin: 0 auto;" v-if="!lists || lists.length == 0" description="暂无数据"></el-empty>

        </div>

    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},
    props: {
        id: ''
    },
    data() {
        return {
            projectType: '',
            pageSize: 4,
            pageNum: 1,
            total: 0,
            lists: []
        };
    },
    created() {
        this.projectType = localStorage.getItem('projectType');
        this.selectSchoolDemeanourPage()
    },
    mounted() {

    },
    methods: {
        selectSchoolDemeanourPage() {
            know.selectSchoolDemeanourPage({
                pageNum: this.pageNum,
                isDemeanour: 1,
                pageSize: this.pageSize,
                schoolId: this.id,
            })
                .then((res) => {
                    this.lists = res.rows
                });
        },
    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    padding-bottom: 20px !important;
    // padding-top: 10px;
    padding-top: 0;

    .school_detail {
        position: relative;
        border-radius: 3px 3px 3px 3px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0;

        .line {
            position: absolute;
            width: 6px;
            height: 24px;
            background: #1061FF;
            border-radius: 2px;
            left: 0;
            top: 25px;
        }

        .s_til {
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: bold;
            font-size: 24px;
            color: #444444;
        }

        .s_tils {
            cursor: pointer;

            img {
                width: 38px;
            }
        }

    }

    .detail_list {
        display: flex;
        align-items: center;

        .detail_item {
            width: 270px;
            height: 260px;
            background: #F3F4F8;
            border-radius: 4px 4px 4px 4px;
            margin-right: 20px;
            overflow: hidden;

            .detail_item_img {
                width: 270px;
                height: 203px;

            }

            .detail_item_til {
                width: 270px;
                height: 60px;
                background: #F3F4F8;
                border-radius: 4px 4px 4px 4px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: normal;
                font-size: 16px;
                color: #444444;
                display: flex;
                justify-content: center;
                align-items: center;

                >div {
                    width: 200px;
                    text-align: center;
                }
            }
        }
    }




}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>