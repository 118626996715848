<template>
    <div class="mainboxs">
        <div class="teacher_list">
            <div class="carousel">
                <el-carousel indicator-position="none" :interval="0" arrow="always" ref="carousel">
                    <el-carousel-item v-for="(item, index) in groupedArray" :key="index">
                        <div class="slide_list">
                            <div @mouseenter="mouseIn(itemd)" @mouseleave="mouseOut(itemd)"
                                v-for="(itemd, indexd) in item" :key="indexd" class="slide_item">
                                <div class="slide_item_bot">
                                    <div class="slide_item_head">
                                        <img :src="itemd.teacherImg" alt="">
                                    </div>
                                    <div class="ter_name elp">{{ itemd.teacherName }}</div>
                                    <div class="line"></div>
                                    <div class="slide_item_msg twoEllipsis">{{ itemd.teacherIntroduction }}</div>
                                </div>
                                <div v-if="itemd.isShow" class="pop">
                                    <div class="p_text ">
                                        {{ itemd.teacherIntroduction }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},
    props: {
        id: ''
    },
    data() {
        return {
            projectType: '',
            pageSize: 15,
            pageNum: 1,
            total: 0,
            lists: [],
            groupedArray: []
        };
    },
    created() {
        this.projectType = localStorage.getItem('projectType');
        this.selectSchoolTeacherPage()
    },
    mounted() {

    },
    methods: {
        selectSchoolTeacherPage() {
            know.selectSchoolTeacherPage({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                isTeacher: 1,
                schoolId: this.id,
            }).then((res) => {
                this.lists = res.rows
                if (this.lists && this.lists.length > 0) {
                    this.groupedArray = this.lists.reduce((result, value, index) => {
                        if (index % 4 === 0) {
                            result.push([]);
                        }
                        result[Math.floor(index / 4)].push(value);
                        return result;
                    }, []);
                }

                console.log(this.groupedArray, 'groupedArray');
            });
        },
        nextSlide() {
            this.$refs.carousel && this.$refs.carousel.next();
        },
        prevSlide() {
            this.$refs.carousel && this.$refs.carousel.prev();
        },
        mouseIn(item) {
            item.isShow = true
            this.$forceUpdate()
        },
        mouseOut(item) {
            item.isShow = false
            this.$forceUpdate()

        }
    },
};
</script>

<style lang="less" scoped>
.mainboxs {
    width: 100% !important;
    background: url('~@/assets/img/TechnicalCollege/10.png');
    background-size: 100% 100%;
    height: 344px;

    .teacher_list {
        width: 1200px;
        margin: 0 auto;
        padding: 20px 0;
        padding-top: 40px;
    }



}

.slide_list {
    width: 1050px;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    // justify-content: space-between;


    .slide_item {
        position: relative;
        width: 241px;
        height: 272px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        cursor: pointer;
        margin-right: 20px;

        .slide_item_bot {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 242px;
            height: 232px;
            background: linear-gradient(180deg, #FFFFFF 0%, #F7F3EE 100%);
            border-radius: 6px 6px 6px 6px;
            padding: 0 13px;

            .slide_item_head {
                position: absolute;
                width: 145px;
                height: 145px;
                border-radius: 50%;
                border: 1px solid #BEBFC1;
                display: flex;
                justify-content: center;
                align-items: center;
                top: -40px;

                img {
                    width: 124px;
                    height: 124px;
                    border-radius: 50%;
                }
            }

            .ter_name {
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                font-weight: bold;
                font-size: 16px;
                color: #333333;
                text-align: center;

                margin-top: 110px;
            }

            .line {
                width: 29px;
                height: 2px;
                background: #BD1212;
                border-radius: 2px 2px 2px 2px;
                margin: 0 auto;
                margin-top: 7px;
            }

            .slide_item_msg {
                width: 200px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: normal;
                font-size: 14px;
                color: #666666;
                margin-top: 12px;
            }
        }

        .pop {
            position: absolute;
            width: 241px;
            height: 272px;
            background: rgba(44, 44, 44, 0.8);
            border-radius: 8px 8px 8px 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            .p_text {
                width: 211px;
                height: 190px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: normal;
                font-size: 14px;
                color: #FFFFFF;
                overflow-y: scroll;
            }

        }

        &:nth-child(1) {
            margin-left: 10px;
        }
    }

}

img {
    display: block;
    width: 100%;
    height: 100%;
}

::v-deep .el-carousel__arrow {
    width: 54px !important;
    height: 54px !important;
    background: #FFFFFF;

    ::v-deep .el-carousel__arrow--left {
        background: url('~@/assets/img/TechnicalCollege/117.png');
        background-size: 100% 100%;
        color: #000;
        font-size: 28px;
    }

    .el-icon-arrow-left {
        color: #000;
        font-size: 28px;
    }

    .el-icon-arrow-right {
        color: #000;
        font-size: 28px;
    }
}
</style>