<template>
    <div class="mainbox">
        <div class="school_detail">
            <div class="line"></div>
            <div class="s_til">{{ schooltem.schoolName }}简介</div>
            <!-- <div style="min-height: 100px;" class="s_msg ">{{ schooltem.introductory }}</div> -->

            <div style="min-height: 100px;" ref="message" class="s_msg ">

            </div>
            <div class="s_detail">
                <div v-if="projectType == 0" class="s_item">
                    <div>招生类别：</div>
                    <div style="width: 150px;" class="elp">{{ schooltem.projectCategoryName }}</div>
                </div>
                <div v-if="projectType == 0" class="s_item">
                    <div>招生层次：</div>
                    <div style="width: 150px;" class="elp">{{ schooltem.level }}</div>
                </div>
                <div v-if="projectType == 0" class="s_item">
                    <div>学习形式：</div>
                    <div style="width: 150px;" class="elp">{{ schooltem.studyType }}</div>
                </div>
                <div v-if="projectType == 0" class="s_item">
                    <div>院校地址：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.areaAddress }}</div>
                </div>
                <div v-if="projectType == 0" class="s_item">
                    <div>招生专业：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.majorName }}</div>
                </div>
                <div v-if="projectType == 0" class="s_item">
                    <div>办学优势：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.advantage }}</div>
                </div>
                <div v-if="projectType == 5" class="s_item">
                    <div>留学模式：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.studyModeNameList }}</div>
                </div>
                <div v-if="projectType == 5" class="s_item">
                    <div>留学国家：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.countryNameList }}</div>
                </div>
                <div v-if="projectType == 5" class="s_item">
                    <div>学校优势：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.advantage }}</div>
                </div>
                <div v-if="projectType == 1" class="s_item">
                    <div>招生类别：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.projectCategoryName }}</div>
                </div>
                <div v-if="projectType == 1" class="s_item">
                    <div>院校地址：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.areaAddress }}</div>
                </div>
                <div v-if="projectType == 1" class="s_item">
                    <div>招生专业：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.majorName }}</div>
                </div>
                <div v-if="projectType == 1" class="s_item">
                    <div>学校优势：</div>
                    <div style="width: 600px;" class="elp">{{ schooltem.advantage }}</div>
                </div>
            </div>
        </div>
        <div class="s_news">
            <div class="line"></div>
            <div class="s_til">
                <div>学校新闻</div>
                <div style="cursor: pointer;" @click="goNext(`/gxGathers/school/decs?id=${id}`)">更多 <img
                        style="width: 12px;height: 12px;margin-left: 6px;" src="~@/assets/img/TechnicalCollege/48.png"
                        alt=""></div>
            </div>
            <div class="news_concents">
                <div @click="goNext(`/gxGathers/school/decsDetail?newid=${item.id}&id=${id}`)"
                    v-for="(item, index) in qusetionList" :key="index" class="news_item">
                    <div class="news_con_left">
                        <div></div>
                        <div class="elp">{{ item.newTitle }}</div>
                    </div>
                    <div v-if="item.sort == 1"> <img style="width: 33px;margin-left: 6px;"
                            src="~@/assets/img/TechnicalCollege/46.png" alt="">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},
    props: {
        id: ''
    },
    data() {
        return {
            search: {
                pageSize: 11,
                pageNum: 1,
                projectTypeId: '',
                schoolId: ''
            },
            whichNews: 1,
            schooltem: {},
            qusetionList: [],
            projectType: ''
        };
    },
    created() {
        this.search.projectTypeId = localStorage.getItem('projectTypeId');
        this.projectType = localStorage.getItem('projectType');
        this.search.schoolId = this.$route.query.id;
        this.selectSchoolPcData()
        this.getContentNewPcList()
    },
    mounted() {

    },
    methods: {
        truncateText() {
            const messageElement = this.$refs.message;
            const text = this.schooltem.introductory;
            if (text.length > 200) {
                const truncatedContent = text.substr(0, 200);
                messageElement.textContent = truncatedContent + '...';
                const link = document.createElement('a');
                link.textContent = '查看详情';
                link.style.color = '#1061FF';
                link.style.cursor = 'pointer';
                link.addEventListener('click', () => {
                    this.goNext(`/gxGathers/school/synopsis?id=${this.id}`);
                });
                // link.click(this.goNext(`/gxGathers/school/synopsis?id=${this.id}`))
                messageElement.appendChild(link);
            } else {
                messageElement.textContent = text;
            }
        },
        selectSchoolPcData() {
            if (this.projectType == 0) {
                know.selectSchoolPcData({
                    id: this.id,
                }).then((res) => {
                    this.schooltem = res.data
                    this.truncateText()
                });
            } else if (this.projectType == 5) {
                know.getStudySchoolData({
                    id: this.id,
                }).then((res) => {
                    this.schooltem = res.data
                    this.truncateText()
                });
            } else if (this.projectType == 1) {
                know.getSchoolPcData({
                    id: this.id,
                }).then((res) => {
                    this.schooltem = res.data
                    this.truncateText()
                });
            }


        },
        getContentNewPcList() {
            know.getContentNewPcList({ ...this.search }).then((res) => {
                this.qusetionList = res.rows
                this.total = res.total
            });
        },
    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;

    .school_detail {
        position: relative;
        width: 840px;
        height: 400px;
        background: #fff;
        border-radius: 3px 3px 3px 3px;
        padding: 20px;

        .line {
            position: absolute;
            width: 6px;
            height: 24px;
            background: #1061FF;
            border-radius: 0px 4px 4px 0px;
            left: 0;
            top: 23px;
        }

        .s_til {

            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: bold;
            font-size: 24px;
            color: #444444;
        }

        .s_msg {
            width: 788px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 14px;
            color: #555555;
            line-height: 24px;
            margin-top: 22px;
        }

        .s_detail {
            width: 789px;
            padding: 20px;
            padding-bottom: 0;
            background: linear-gradient(180deg, rgba(238, 238, 238, 1) 5%, #F7FAFF 100%);
            box-shadow: 0px 2px 6px 1px rgba(8, 54, 143, 0.07);
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #FFFFFF;

            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 14px;
            color: #555555;
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;

            .s_item {
                display: flex;
                align-items: center;
                margin-right: 10px;
                margin-bottom: 20px;

                >div:nth-child(1) {

                    font-family: PingFangSC-Medium, PingFangSC-Medium;
                    font-weight: bold;
                    font-size: 16px;
                    color: #555555;
                    margin-right: 5px;
                }

                // >div:nth-child(2) {
                //     width: 150px;
                // }

            }
        }



    }

    .s_news {
        position: relative;
        width: 341px;
        height: 400px;
        overflow: hidden;
        background: #FFFFFF;
        border-radius: 3px 3px 3px 3px;
        padding: 20px;

        .line {
            position: absolute;
            width: 6px;
            height: 18px;
            background: #1061FF;
            border-radius: 0px 4px 4px 0px;
            left: 0;
            top: 28px;
        }

        .s_til {

            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: bold;
            font-size: 24px;
            color: #444444;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >div:nth-child(2) {
                display: flex;
                align-items: center;
                font-family: PingFangSC-Regular, PingFangSC-Regular;
                font-weight: normal;
                font-size: 14px;
                color: #777777;
            }
        }

        .news_concents {
            // padding: 15px;
            padding-top: 15px;

            .news_item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                margin-bottom: 10px;
            }

            .news_con_left {
                display: flex;
                align-items: center;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: normal;
                font-size: 14px;
                color: #444444;
                cursor: pointer;

                >div:nth-child(1) {
                    width: 3px;
                    height: 3px;
                    background: #E1E1E1;
                    border-radius: 50%;
                    margin-right: 10px;
                    flex-shrink: 0;
                }

                >div:nth-child(2) {
                    width: 200px;
                }
            }

            .news_con_left:hover {
                color: #BD1212;

                >div:nth-child(1) {
                    background: #BD1212;
                    border-radius: 50%;


                }
            }
        }
    }

}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>