<template>
    <div class="mainbox">
        <schoolDetail :id="id" style="margin-top: 40px;" class="banner" />
        <enrollmentGuide :id="id" />
        <teacher style="margin-top: 30px;" :id="id" />
        <schoolImg style="margin-top: 30px;margin-bottom: 30px;" :id="id" />
        <submit class="sub" v-if="projectType == 0" :id="id" />
        <submit1 class="sub" v-if="projectType == 1" :id="id" />
        <submit2 class="sub" v-if="projectType == 5" :id="id" />
        <question style="margin-top: 30px;" :id="id" />
        <!-- <submit style="margin-top: 30px;" :id="id" /> -->

    </div>
</template>

<script>
import {
    getProjectClassList,
    getProjectClassLis,
    selectInformationPcList,
} from "@/api/home";
import { getDomain } from "@/api/cookies";
import schoolDetail from "./schoolDetail.vue";
import enrollmentGuide from "./enrollmentGuide.vue";
import teacher from "./teacher.vue";
import schoolImg from "./schoolImg.vue";
import question from "./question.vue";
import submit from "@/views/gxGather/school/pages/submit.vue";
import submit1 from "@/views/gxGather/graduate/submitschool.vue";
import submit2 from "@/views/gxGather/abroad/submitschool.vue";

export default {
    components: {
        schoolDetail, enrollmentGuide, teacher, schoolImg, question, submit, submit1, submit2
    },
    props: {
        id: ''
    },
    data() {
        return {
            is_huadong: true,
            projectType: 0,

        };
    },
    created() {
        this.projectType = localStorage.getItem('projectType');
        console.log(this.$route.query, 'this.$route.query');

        // this.id = this.$route.query.id;
        // this.getList();
    },
    mounted() {
        if (this.$route.query.toid) {

            setTimeout(() => {
                document.querySelector('.sub').scrollIntoView({
                    behavior: 'smooth', // 平滑滚动
                    block: 'start' // 将元素的顶部与视图窗口的顶部对齐
                });
            }, 1000);

        }
        this.$router.beforeEach((to, from, next) => {
            console.log(this.$route.query.toid, 'this.$route.query.toid');
            if ((to.path == from.path) && this.$route.query.toid) {
                setTimeout(() => {
                    document.querySelector('.sub').scrollIntoView({
                        behavior: 'smooth', // 平滑滚动
                        block: 'start' // 将元素的顶部与视图窗口的顶部对齐
                    });
                }, 1000);
            } else {


            }

            next(); // 必须调用 next() 来继续路由导航
        });
    },
    methods: {

    },
};
</script>

<style lang="less" scoped>
.mainbox {
    // width: 1200px;
    margin: 0 auto;
    padding-bottom: 10px;
}
</style>